<template>

  <div class="checkout-items">
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form  @submit.prevent="fetch(1)">
              



              <b-row>
                <b-col>
                  <b-form-input class="mr-1" v-model="filter.q"
                                placeholder="Recherche par Marque, Modèle, Immat"/>
                </b-col>
                
                

<!--                <b-col v-if="$can('voir-catalog-concession', 'catalogue') || $can('voir-catalog-global', 'catalogue')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>-->
<!--                </b-col>-->
<!--                <b-col v-if="$can('voir-catalog-concession', 'catalogue') || $can('voir-catalog-site', 'catalogue') || $can('voir-catalog-global', 'catalogue')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>-->
<!--                </b-col>-->
                <b-col md="auto">
                  <b-button type="submit" variant="primary">Recherche</b-button>
                  <b-button v-if="$can('exporter-catalogue', 'vehicule')" :disabled="pendindExport" @click="importHandler" class="mx-1" variant="primary"> <b-spinner v-show="pendindExport" small>  </b-spinner><span>Exporter</span> </b-button>
                  <span>Total : {{pagination.total}}</span>
                </b-col>
                
              </b-row>
              
              
              
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="is_loading">
      <b-row>

        <b-col :key="index" v-for="(item, index) in catalague" md="6" lg="4">

          <b-card
              no-body
              class="card-developer-meetup cursor-pointer"
              @click="$router.push({name : 'catalogue-view', params : {id : item.id}})"
          >
            <div class="vehicule-image rounded-top text-center"
                 :style="'background-image: url(' + item.thumbnail + ')'">

            </div>
            <b-card-body class="p-1">
              <!-- metting header -->
              <div class="d-flex align-items-center justify-content-between">

                <div>
                  <b-card-title class="mb-25">
                    <div class="d-flex justify-content-between">
                        <div>{{ item.marque }} {{ item.modele }}</div>
                      
                    </div>
                     
                  </b-card-title>
                  <b-card-text class="mb-0">
                 
                    {{ item.version }}<br/>
                    Immat : {{item.immatriculation}} <span class="font-weight-bold">|</span> MEC : {{item.date_mec}}<br/>
                    <span> <feather-icon
                        icon="MapPinIcon"
                        size="14"
                    />  {{ item.site }}</span>   

                  </b-card-text>
                </div>
                <div>
                  <h5>
                    {{ item.prix_vente_particulier_ttc.toLocaleString('en-US') }} Dhs
                  </h5>

                </div>

              </div>

              <hr class="">

              <!-- follower projects rank -->
              <div class="d-flex justify-content-between align-items-center font-small-4">
                <div>

                  <div class="car-icon mb-0"
                       :style="{backgroundImage: `url(${require('@/assets/images/cars/compteur-de-vitesse.png')})`}">
                    {{ item.kilometrage.toLocaleString('en-US') }} KM
                  </div>
                </div>
                <div>

                  <div class="car-icon mb-0"
                       :style="{backgroundImage: `url(${require('@/assets/images/cars/changement-de-vitesse.png')})`}">
                    {{ item.type_boite }}
                  </div>
                </div>
                <div>

                  <div class="car-icon mb-0"
                       :style="{backgroundImage: `url(${require('@/assets/images/cars/pompe-a-carburant.png')})`}">
                    {{ item.energie }}
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>

        </b-col>

      </b-row>
    </b-overlay>
    <b-row v-if="!is_loading && catalague.length == 0">
      <b-col>
        <b-card class="text-center">
          Aucun véhicule trouvé dans catalogue
        </b-card>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>


import axios from "@/libs/axios";

export default {
  name: 'CataloguesList',
  data() {
    return {
      pendindExport: false,
      catalague: {},
      pagination: {},
      current_page: 1,
      filter: {
        q: ''
      },
      is_loading: false
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    importHandler() {
      let _this = this;
      _this.pendindExport = true;
      axios.get(
          process.env.VUE_APP_API_URL + 'api/vehicules/catalogue?export=1&q=' + _this.filter.q,
          {responseType: 'blob'} // !!!
      ).then((response) => {
        _this.pendindExport = false;
        window.open(URL.createObjectURL(response.data));
      })
    },
    recherchHandler() {
      this.fetch(1);
    },
    fetch(page) {
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('catalogueModule/fetchCatalogues', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.catalague = response.data;
        _this.pagination = response.meta;
        _this.is_loading = false;
      });
    }
  }
}
</script>
<style lang="scss">
//@import '@core/scss/vue/libs/vue-wizard.scss';
//@import '@core/scss/base/pages/app-ecommerce.scss';
//@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.vehicule-image {
  height: 300px;
  background-size: cover;
  background-position: center;
}
</style>
